.scrolling-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.project__iframe {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}